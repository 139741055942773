import React from "react";
import { graphql } from "gatsby";

import Image from "../components/Image";
import Layout from "../components/Layout";
import NewsPanel from "../components/NewsPanel";
import SidePanel from "../components/SidePanel";
import ShareButtons from "../components/ShareButtons";
import { GetMetaData } from "../queries/metadata";

export const pageQuery = graphql`
  query currentPostQuery($id: ID!) {
    wp {
      post(id: $id) {
        date
        title
        content
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            ...MediaItem
          }
        }
      }
    }
  }
`;

const Single = ({ data, location }) => {
  const post = data.wp.post;
  const metadata = GetMetaData();
  const siteTitle = metadata.site.siteMetadata.title;

  return (
    <Layout title={post.title}>
      <div className="2xl:container relative lg:flex flex-wrap items-start justify-end lg:pr-36">
        <SidePanel smallfont={true} title={post.title} />
        <div className="w-full flex flex-col items-center lg:items-end justify-start lg:mt-36">
          {post.featuredImage && (
            <Image
              image={post.featuredImage.node}
              alt={post.title}
              className={`relative z-1 w-full lg:w-[70%] lg:min-h-[600px]`}
            />
          )}
          <div
            className="px-12 lg:pr-0 lg:w-[65%] py-12 lg:pl-32 prose"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          <ShareButtons
            siteTitle={siteTitle}
            shareUrl={location.href}
            title={post.title}
          />
        </div>
      </div>
      <NewsPanel heading="Other News" />
    </Layout>
  );
};

export default Single;
