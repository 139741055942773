import React from "react";

const SidePanel = ({ title, smallfont }) => {
  return (
    <div
      className={`${
        smallfont ? "flex justify-start items-center lg:block" : ""
      } mt-28 lg:mt-32 lg:absolute bottom-0 left-0 z-10 lg:w-[35%] px-12 pt-14 pb-32 lg:py-16 side-height lg:py-12 xl:px-24 text-white bg-gradient-to-b from-cerulean-500 to-cerulean-700`}
    >
      <h2
        className={`text-5xl leading-tight font-bold mb-4 text-white ${
          smallfont ? "text-3xl lg:text-5xl" : "text-5xl uppercase"
        }`}
      >
        {title}
      </h2>
    </div>
  );
};

export default SidePanel;
