import React from "react";
import { FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa";
import {
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

const ShareButtons = ({ siteTitle, shareUrl, title }) => {
  return (
    <div className="pb-24 pl-12 lg:pl-32 w-full lg:w-[65%] flex space-x-5 text-3xl text-cerulean">
      <EmailShareButton
        subject={`${title} | ${siteTitle}`}
        url={shareUrl}
        title={title}
      >
        <FaEnvelope />
      </EmailShareButton>
      <LinkedinShareButton url={shareUrl} source={siteTitle} title={title}>
        <FaLinkedin />
      </LinkedinShareButton>
      <TwitterShareButton url={shareUrl} title={title}>
        <FaTwitter />
      </TwitterShareButton>
    </div>
  );
};

export default ShareButtons;
